<template>
<div class="terms">
  <!-- header  -->
  <header class="header-section has-header-main">
    <!-- Header main -->
    <HeaderMain></HeaderMain>
  </header>


  <!-- product  -->
  <section class="section-space trending-section" >
    <div class="container">
      <!-- section heading -->
      <SectionHeading classname="text-center" text="Terms & Condition" content="" isMargin="mb-3"></SectionHeading>



      <section class="section pt-0" style="margin: 0 10px;">
        <div >
          <h4 style="font-size:22px;">
            <span style="font-size: 17px; font-weight: normal;">Last Updated: 5 July 2021</span>
          </h4>
          <br/>
          <br/>
          <p>This agreement is made between you as a user and platform GDMining.
            <br/><br/>
            Using the services of the site GDMining, you agree that you have read and accepted all the conditions described in this agreement, as well as familiarized with our Privacy Policy.
            <br/><br/>
            If you do not agree with this agreement, immediately stop using the services of the platform GDMining.
            <br/><br/>
            By creating your account at GDMining, you consent to the terms and conditions set forth in this User Agreement.
            <br/><br/>
            If you have any related questions, you can always contact our support team.</p>
          <br/>
          <br/>
          <h4 style="font-size:22px;" class="my-gs">
            Terms and Definitions&nbsp;
          </h4><br/>
          <p>1. Agreement is a list of the conditions of Users that must be accepted to continue working on the platform GDMining.</p>
          <p>2. Cryptocurrency - a peer-to-peer electronic currency that does not have a single centralized issuer, which is distributed between holders of such a currency.</p>
          <p>3. A personal account is a page inside the platform that is created as a result of registration and acceptance of the User Agreement.</p>
          <p>4. Privacy policy - these are the rules for the collection, storage and use of personal data of users.</p>
          <p>5. User is a natural or legal person registered in accordance with local national legislation, registered and accepted the User Agreement.</p>
          <p>6. Deposit and withdrawal of funds - an operation to transfer the user's funds from a personal account.</p>
          <p>7. Account data - personal data of the user, this is the username, password, email address that the user specified when registering on the platform GDMining.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Terms of agreement
          </h4>
          <p>About such changes GDMining will notify all users by the specified mail by the user, and all updates will be displayed in the Agreement Terms themselves.</p>
          <p>If you continue to work on the platform, then you automatically accept these updates.</p>
          <p>By creating an account in GDMining, you confirm that you are at least 18 years old and you are an individual or legal entity, an organization that is fully authorized to accept this User Agreement between you and GDMining.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Prohibition of use
          </h4>
          <p>Using the services of the platform GDMining, you declare that you are not on any list of trade or economic sanctions, such as the List of Sanctions of the UN Security Council and the like.</p>
          <p>This Agreement cannot be excluded under the laws of the country in which the user is located.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Description of the services&nbsp;
          </h4>
          <p>Users have the opportunity to register, open accounts and deposit assets in GDMining. Platform users have the right to request the withdrawal of their funds, taking into account the restrictions specified in the Terms of the agreement, if any.</p>
          <p>All users should be aware of all the risks associated with all possible movements of cryptocurrency rates.</p>
          <p>GDMining never requires transfers of funds to the wallet and never asks for your account password.</p>
          <p>Users need to be careful about promotions and any discounts that could lead to fraud and loss of funds. All notifications that come to the e-mail specified by users will never require any transaction data, personal data until a user requests it.</p>
          <p>The list of fraudulent transactions is wider, the user agrees that GDMining does not take responsibility for any loss of funds resulting from the situations listed above.</p>
          <p>By using the services of GDMining, you confirm that all information provided by GDMining under this Agreement is true, complete and accurate.</p>
<!--          <p>According to the requirements of the relevant government department, account balance exceeding $10,000 require a 15% tax payment.</p>-->
<!--          <p>According to the requirements of the relevant government department, require a 15% tax payment.</p>-->

          <h4 style="font-size:22px;" class="my-gs">
            Registration and account requirements
          </h4>
          <p>Before starting work on the platform GDMining all users must go through a simple registration.</p>
          <p>And also users need to accept the Terms of Use, Privacy Policy.</p>
          <p>To create an account you need to provide your real name, email address and password.</p>
          <p>At our sole discretion and depending on a number of conditions, GDMining may refuse to open an account.</p>

          <h4 style="font-size:22px;" class="my-gs">
            User data authentication
          </h4>
          <p>By creating an account with GDMining, you agree to provide personal data to verify the authenticity of your identity.</p>
          <p>These measures are necessary to detect and prevent schemes of money laundering, fraud, financing of terrorist organizations and other financial crimes.</p>
          <p>After registration, you must ensure that your information provided is complete, truthful, and you will be updated in a timely manner in the event of a change.</p>
          <p>If there is any doubt that the information you submitted is untrue or is outdated or incomplete, GDMining has the right to send you a notification asking you to correct or update personal data.</p>
          <p>You take full responsibility for any losses or expenses incurred at the time of use GDMining if you cannot contact in a timely manner and update your personal information.</p>
          <p>By this agreement, you acknowledge that you undertake to update personal information in case of any changes.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Account Requirements
          </h4>
          <p>GDMining has the right to freeze or cancel accounts used by third parties that are not account holders.</p>
          <p>GDMining is not responsible for these accounts.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Account security
          </h4>
          <p>GDMining, strives to create maximum security to preserve user funds.</p>
          <p>You are solely responsible for maintaining the security of your account at GDMining, for maintaining the password, and the electronic login address.</p>
          <p>Our company asks you, as account holders, to also be extremely careful and take precautions to protect your account and personal information.</p>
          <p>You will comply with the security system, authentication, processing and collection of payments or withdrawals.
            you immediately inform GDMining if you become aware of any unauthorized use of your account and password in GDMining by another person, or other violations of security rules.
            GDMining does not take responsibility for any losses and consequences caused by your neglect of the above account security statement.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Copyright and trademarks
          </h4>
          <p>In order to properly use Intellectual Property GDMining, obtaining the necessary permissions is required.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Recommendations for use
          </h4>
          <p>You hereby agree to abide by these points when you use the platform GDMining:</p>
          <p>will not shy away from paying taxes or government fees.
            all the actions you take while working on GDMining will meet the requirements of applicable laws, as well as charters GDMining.
            this agreement will not be violated, and the rules GDMining.
            If you have violated the above paragraphs and this has led to any legal consequences, you will independently carry out all legal paragraphs on your own behalf and protect GDMining from all claims and costs associated with such a violation.</p>
          <p>You will use the platform in accordance with the Privacy Policy and Terms of Use, without interfering with the normal operation of the platform GDMining.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Limitation of liability
          </h4>
          <p>GDMining is not responsible for any of your losses resulting from the events described below, but not limited to:</p>
          <p>unauthorized alteration of your data by third parties.
            loss of profit, goodwill or other intangible losses.
            unauthorized use of your account.
            other losses.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Recompense
          </h4>
          <p>You agree to protect GDMining, its current employees, directors, affiliates and agents from all kinds of losses incurred by a third party as a result of User GDMining actions in violation of this Agreement.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Termination
          </h4>
          <p>You agree that GDMining has the right to stop your use of the platform, block funds if it is suspected that such accounts have violated the Terms of the Agreement, the Privacy Policy, or other applicable legislation. GDMining, has the right to save, use and provide transaction data of violators at the request of the legislature.</p>
          <p>The termination of the agreement is possible with such factors:</p>
          <p>by order of a court or other regulatory authority.
            if unauthorized access to the account is detected.
            if non-natural account activity is detected.
            After the account is closed and the funds are blocked, the entire balance of the user will go to pay off all costs and expenses GDMining.
          </p>

          <h4 style="font-size:22px;" class="my-gs">
            Privacy policy
          </h4>
          <p>Privacy policy is an important clause of this agreement. GDMining may, if necessary, amend this clause of the agreement. All changes to the Privacy Policy, and any other clause, will be notified to all users through the mailing list indicated by their email.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Compensation and Disclaimer
          </h4>
          <p>By accepting this agreement, the user agrees to reimburse all losses and expenses incurred GDMining if the user's actions led to such losses.</p>
          <p>GDMining is not responsible for errors and inaccuracies associated with the use of its services.</p>

          <h4 style="font-size:22px;" class="my-gs">
            Support service
          </h4>
          <p>If you have any questions about these terms, please contact info@gdmining.com</p>


        </div>
      </section>

    </div><!-- .container -->
  </section><!-- trending-section -->





  <!-- Footer  -->
  <Footer classname="bg-cus on-dark"></Footer>
</div>
</template>

<script>
import HeaderMain from "@/components/n1/HeaderMain"
import SectionHeading from "@/components/n1/SectionHeading"
import Footer from "@/pages/n1/Footer"

export default {
  name: "Terms",
  components:{
    HeaderMain,
    SectionHeading,
    Footer
  }
}
</script>

<style scoped>
.bg-cus {
  background-color: #ffe5d0;
}
</style>
